import { Stack } from "@mui/material";
import {
  Organization,
  Relation,
  RelationDTO,
  Right,
  SmartButton,
  useMaxLengthValidation,
  useMinLengthValidation,
  useRequiredValidation,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useIcoValidation } from "@sista/library-stasi/dist/components/form/validations/useIcoValidation";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";

export type OrganizationRegistrationForm = {
  idNumber: string;
};

export type OrganizationSearchByIcoContentProps = {
  onFoundOrganization: (organization: Organization) => void;
  onFoundRelationsInOrganization: (relations: RelationDTO[]) => void;
};

export const OrganizationSearchByIcoContent = (
  props: OrganizationSearchByIcoContentProps
) => {
  const ico = useIcoValidation();
  const maxLength = useMaxLengthValidation(8);
  const minLength = useMinLengthValidation(8);
  const required = useRequiredValidation();

  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>(null);

  const { getOrCreateOrganizationByIco, getMyRelationsInOrganization } =
    useRejstrikClient();

  const [getOrCreateOrganizationByIcoCall, getOrCreateOrganizationByIcoState] =
    useSafeSistaAsync(getOrCreateOrganizationByIco);

  const [getMyRelationsInOrganizationCall, getMyRelationsInOrganizationState] =
    useSafeSistaAsync(getMyRelationsInOrganization);

  const submitForm = useCallback(
    async (formData: OrganizationRegistrationForm) => {
      getOrCreateOrganizationByIcoCall({ ico: formData.idNumber });
    },
    [getOrCreateOrganizationByIcoCall]
  );

  const form = useForm<OrganizationRegistrationForm, unknown>({
    defaultValues: {
      idNumber: "40612350",
    },
  });

  useEffect(() => {
    if (getOrCreateOrganizationByIcoState.value != null) {
      getMyRelationsInOrganizationCall({
        organizationId: getOrCreateOrganizationByIcoState.value.id,
      });
    }
  }, [
    getMyRelationsInOrganizationCall,
    getOrCreateOrganizationByIcoState.value,
  ]);

  useEffect(() => {
    if (getMyRelationsInOrganizationState.value != null) {
      props.onFoundOrganization(getOrCreateOrganizationByIcoState.value);
      props.onFoundRelationsInOrganization(
        getMyRelationsInOrganizationState.value
      );
    }
  }, [
    getMyRelationsInOrganizationState.value,
    getOrCreateOrganizationByIcoState.value,
    props,
  ]);

  return (
    <FormContainer onSuccess={submitForm} formContext={form}>
      <Stack direction="column" spacing={2} mt={2}>
        <TextFieldElement
          name={"idNumber"}
          autoComplete={"off"}
          label={t("ucet.organization.form.idNumber", "IČ")}
          validation={{
            ...required,
            ...maxLength,
            ...minLength,
            ...ico,
          }}
        />
        <Right sx={{ marginTop: 4 }}>
          <SmartButton state={getOrCreateOrganizationByIcoState}>
            {t("ucet.organization.form.search", "Vyhledat organizaci")}
          </SmartButton>
        </Right>
      </Stack>
    </FormContainer>
  );
};
