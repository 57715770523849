import { Button } from "@mui/material";
import { openSelf } from "@sista/fe-commons";
import { IdmClientContext } from "@sista/idm-client";
import { isDev } from "@sista/library-stasi";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import { useIdmClient } from "../../api/utils/useIdmClient";

type NiaButtonProps = {
  children?: React.ReactNode;
};

const url = process.env.REACT_APP_IDM_ROOT + "/api/v1/nia/login";

export const NiaButton = (props: NiaButtonProps) => {
  const { t } = useTranslation("ucet");
  const { loggedAccount } = useContext(IdmClientContext);
  const { getNiaLoginToken } = useIdmClient();

  const navigateToNia = useCallback(async () => {
    let goto = url;
    if (loggedAccount !== null && loggedAccount.activeProfileId != null) {
      const token = await getNiaLoginToken({});
      goto = url + "?niaLoginToken=" + token.value;
    }
    openSelf(goto);
  }, [getNiaLoginToken, loggedAccount]);

  // takhle lze vypnout na UI NIA button
  // if (!isDev()) return null;

  return (
    <Button onClick={navigateToNia} variant={"text"}>
      {props.children
        ? props.children
        : t("ucet.button.nia", "Použít Identitu občana")}
    </Button>
  );
};

export default NiaButton;
