import { FormControlLabel, Stack, Switch } from "@mui/material";
import { AlertsContext } from "@sista/dista-ui";
import {
  GestorDTO,
  normalizePhoneNumber,
  Right,
  SistaDialog,
  SistaFormHeader,
  SmartButton,
  toPlainDate,
  UiContext,
  useEmailValidation,
  useMaxLengthValidation,
  useMinLengthValidation,
  useNameValidation,
  usePhoneNumberValidation,
  useReasonableDateValidation,
  useRequiredValidation,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  DatePickerElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";
import { ConfigContext } from "../../../hooks/ConfigContext";

type DepartmentInviteForm = GestorDTO & {
  departmentId: string;
  section?: string;
  tempDate?: unknown;
  role?: string;
};

type DepartmentInviteDialogProps = {
  departmentId: string;
  onDone: () => any;
};

export const DepartmentInviteDialog = (props: DepartmentInviteDialogProps) => {
  const { closeDialogs } = useContext(UiContext);
  const alerts = useContext(AlertsContext);
  const [showDetails, setShowDetails] = useState(false);
  const { filterProfileRoles } = useContext(ConfigContext);

  const form = useForm<DepartmentInviteForm, any>({
    defaultValues: {
      departmentId: props.departmentId,
    },
  });
  const { t, i18n } = useTranslation("ucet");

  const required = useRequiredValidation();
  const reasonableDate = useReasonableDateValidation();
  const email = useEmailValidation();
  const phone = usePhoneNumberValidation();
  const nameValidation = useNameValidation();
  const maxLength = useMaxLengthValidation(37);
  const minLength = useMinLengthValidation(2);

  const {
    createGestorAccount,
    setCustomDataToProfileInDepartment,
    addProfileRoleInDepartment,
  } = useRejstrikClient();

  const createGestorImpl = useCallback(
    async (formData: DepartmentInviteForm) => {
      formData.phone = normalizePhoneNumber(formData.phone);
      formData.birthDate = toPlainDate(formData.tempDate);
      const result = await createGestorAccount(formData);
      const profileId = result.value!;
      console.log("ProfileId: " + profileId);
      console.log("showDetails: " + showDetails);
      if (showDetails && formData.section) {
        await setCustomDataToProfileInDepartment({
          departmentId: props.departmentId,
          profileId: profileId,
          generichKey: props.departmentId.replace(":", ".") + ".details",
          changeReason: "invitation from /ucet",
          minorChange: false,
          data: { section: formData.section },
        }).catch((e: any) => {
          console.error(e);
          alerts.showError(
            t(
              "ucet.department.errorNoDetails",
              "Detailnější informace se nepodařilo uložit, doplňte je prosím později."
            )
          );
        });
      }
      if (showDetails && formData.role) {
        await addProfileRoleInDepartment({
          minorChange: false,
          changeReason: "roles set by invitation from /ucet",
          profileId: profileId,
          departmentId: props.departmentId,
          data: {
            role: formData.role,
            entityId: profileId,
            entityType: "profiles",
            refEntityId: props.departmentId,
            refEntityType: "departments",
          },
        }).catch((e: any) => {
          console.error(e);
          alerts.showError(
            t(
              "ucet.department.errorNoDetails",
              "Roli uživatele se nepodařilo uložit, doplňte ji prosím později."
            )
          );
        });
      }
      alerts.showSuccess(
        t("ucet.department.initationSent", "Pozvánka byla odeslána")
      );
      props.onDone();
      closeDialogs();
      return result;
    },
    [
      createGestorAccount,
      showDetails,
      alerts,
      t,
      props,
      closeDialogs,
      setCustomDataToProfileInDepartment,
      addProfileRoleInDepartment,
    ]
  );

  const [createGestorCall, createGestorState] =
    useSafeSistaAsync(createGestorImpl);

  const handleDetailsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newChecked = event.target.checked;
      setShowDetails(newChecked);
    },
    []
  );

  const roles = useMemo(
    () =>
      filterProfileRoles("departments")
        .filter((r) => r.role !== "DEPARTMENT_EMPLOYEE")
        .filter((r) => r.role !== "DEPARTMENT_ZOG"),
    [filterProfileRoles]
  );

  return (
    <SistaDialog
      title={t("ucet.department.inviteDialogTitle", "Pozvat nového uživatele")}
      onClose={closeDialogs}
    >
      <SistaFormHeader
        title={t("ucet.department.inviteTitle", "Pozvánka")}
        subtitle={t(
          "ucet.department.inviteSubtitle",
          "Přizvěte další uživatele, kteří budou spolupracovat na projektech resortu."
        )}
      />

      <FormContainer onSuccess={createGestorCall} formContext={form}>
        <Stack direction="column" spacing={2}>
          <TextFieldElement
            name="email"
            autoComplete={"email"}
            label={t("ucet.department.form.email", "Pracovní e-mail uživatele")}
            validation={{ ...required, ...email, ...maxLength }}
          />
          <TextFieldElement
            name="titlesBefore"
            autoComplete={"off"}
            label={t("ucet.department.form.titlesBefore", "Tituly před jménem")}
            validation={{}}
          />
          <TextFieldElement
            name="firstName"
            autoComplete={"off"}
            label={t("ucet.department.form.firstName", "Křestní jméno")}
            validation={{
              ...required,
              ...maxLength,
              ...minLength,
              ...nameValidation,
            }}
          />
          <TextFieldElement
            name="lastName"
            autoComplete={"off"}
            label={t("ucet.department.form.lastName", "Příjmení")}
            validation={{
              ...required,
              ...maxLength,
              ...minLength,
              ...nameValidation,
            }}
          />
          <TextFieldElement
            name="titlesAfter"
            autoComplete={"off"}
            label={t("ucet.department.form.titlesAfter", "Tituly za jménem")}
            validation={{}}
          />

          <FormControlLabel
            label={t(
              "ucet.department.form.showDetails",
              "Zadat detailní informace"
            )}
            control={
              <Switch checked={showDetails} onChange={handleDetailsChange} />
            }
          />

          {showDetails && (
            <>
              <DatePickerElement
                name="tempDate"
                label={t("ucet.department.form.birthDate", "Datum narození")}
                validation={{ ...reasonableDate }}
              />

              <TextFieldElement
                name="section"
                autoComplete={"off"}
                label={t("ucet.department.form.section", "Útvar")}
                validation={{
                  ...maxLength,
                  ...minLength,
                }}
              />
              <TextFieldElement
                name="phone"
                placeholder={t(
                  "ucet.department.form.phonePlaceholder",
                  "999 999 999"
                )}
                autoComplete={"off"}
                label={t("ucet.department.form.phone", "Telefon")}
                validation={{
                  ...phone,
                }}
              />

              <SelectElement
                label={t("ucet.department.form.role", "Role")}
                name="role"
                options={roles.map((r) => ({
                  id: r.role,
                  label: r.name[i18n.language],
                }))}
              />
            </>
          )}

          <Right sx={{ marginTop: 4 }}>
            <SmartButton state={createGestorState}>
              {t("ucet.department.buttonInvite", "Poslat pozvánku")}
            </SmartButton>
          </Right>
        </Stack>
      </FormContainer>
    </SistaDialog>
  );
};
