import { Container } from "@mui/material";
import { LandingPageLayout, LogoProps } from "@sista/dista-ui";
import { useAnonymousUserTopBar } from "@sista/fe-commons";
import { IdmClientContext } from "@sista/idm-client";
import {
  LoggedAccountFlags,
  ProfileType,
  UiContext,
} from "@sista/library-stasi";
import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useIdmClient } from "../../api/utils/useIdmClient";
import { IdmUiContext } from "../../utils/IdmUiContext";
import { MergePersonDialog } from "../dialogs/flags/MergePersonDialog";
import { NiaEmailNeededDialog } from "../dialogs/flags/NiaEmailNeededDialog";
import { PersonDetailsDialog } from "../dialogs/flags/PersonDetailsDialog";
import { RegistrationFinishDialog } from "../dialogs/flags/RegistrationFinishDialog";
import { TwoAuthLoginDialog } from "../dialogs/flags/TwoAuthLoginDialog";
import { VerifyAccountDialog } from "../dialogs/flags/VerifyAccountDialog";
import { VerifyNiaAccountDialog } from "../dialogs/flags/VerifyNiaEmailDialog";
import { IntentPicker } from "../ui/IntentPicker";

let globalWorking = false;

export const SemiAccountContent = () => {
  const idm = useContext(IdmClientContext);
  const ui = useContext(UiContext);
  const idmUi = useContext(IdmUiContext);
  const [working, setWorking] = useState(false);
  const navigate = useNavigate();

  const effectiveFlag: LoggedAccountFlags | null = useMemo(() => {
    const possibleFlagsInOrder = [
      "verificationNeeded",
      "personDetailsNeeded",
      "checkSimilarPersonNeeded",
      "verifySimilarPersonNeeded",
      "twoAuthNeeded",
      "profileNeeded",
      "niaEmailNeeded",
      "niaEmailVerificationNeeded",
    ];
    const flags = idm.loggedAccount?.flags || [];
    // first flag that is present in variants array
    const result = flags.find((flag) => possibleFlagsInOrder.includes(flag));
    return result as LoggedAccountFlags;
  }, [idm.loggedAccount]);

  const idmClient = useIdmClient();

  const profileNeeded =
    (idm.loggedAccount?.flags || []).indexOf("profileNeeded") >= 0;

  const registerAndRedirect = useCallback(async () => {
    if (working) return;
    if (globalWorking) return;
    setWorking(true);
    globalWorking = true;
    try {
      if (!profileNeeded) {
        console.log("No profile needed");
        return;
      }
      if (idmUi.registrationIntent == null) {
        console.log("No registration intent");
        return;
      }
      // Vytvorime profil ...
      console.log("Creating profile: ", idmUi.registrationIntent);
      const la = await idmClient.createLoggedAccountProfile({
        value: idmUi.registrationIntent,
      });

      // delay 3s
      await new Promise((resolve) => setTimeout(resolve, 3000));

      // A presmerujeme dal ...
      console.log("Profile created, setting loggedAccount to: ", la);
      idm.setLoggedAccount(la);
    } finally {
      setWorking(false);
      globalWorking = false;
    }
  }, [working, profileNeeded, idmUi.registrationIntent, idmClient, idm]);

  //  Niu mas nasazenou na fnx-dev, pushnutou do develu. Pridala jsem novou flagu emailVerificationNeeded do loggedAccountDTO,
  //  pak je potreba posilat potrebny token na /nia/verify-email POSTem  stringovy identifikator = email + zadany token.

  const receiveIntent = useCallback(
    (pt: ProfileType) => {
      idmUi.setRegistrationIntent(pt);
      registerAndRedirect();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idmUi.setRegistrationIntent, registerAndRedirect, ui]
  );

  const topMenu = useAnonymousUserTopBar({
    //showLanguageSelect: true,
    showLogin: false,
  });

  const logoProps = useMemo(() => {
    return {
      onLogoClick: () => navigate("/"),
    } as LogoProps;
  }, [navigate]);

  return (
    // Tady nelze pouzit SistaPageLayout, v prihlasovacim okne potrebujeme zvlastni chovani
    //
    //   Interpretace flagů polopřihlášeného uživatele:
    //
    <LandingPageLayout topMenuProps={topMenu} logoProps={logoProps}>
      {/* Je nutne vedet, jaky chci profil. */}
      {effectiveFlag == "profileNeeded" && idmUi.registrationIntent == null && (
        <Container maxWidth={"lg"}>
          <IntentPicker onIntentSelected={receiveIntent} />
        </Container>
      )}

      {/* Je nutne mit profil */}
      {effectiveFlag == "profileNeeded" && idmUi.registrationIntent != null && (
        <RegistrationFinishDialog
          doRedirect={registerAndRedirect}
          working={working}
        />
      )}

      {/* Je nutne verifikovat email */}
      {effectiveFlag == "verificationNeeded" && <VerifyAccountDialog />}

      {/* Je nutne verifikovat email */}
      {effectiveFlag == "niaEmailVerificationNeeded" && (
        <VerifyNiaAccountDialog />
      )}

      {/* Chybi detaily Google uctu */}
      {effectiveFlag == "personDetailsNeeded" && <PersonDetailsDialog />}

      {/* Propojeni s jinou osobou */}
      {effectiveFlag == "checkSimilarPersonNeeded" && <MergePersonDialog />}

      {/* Propojeni s jinou osobou */}
      {effectiveFlag == "verifySimilarPersonNeeded" && <MergePersonDialog />}

      {/* Chybi email */}
      {effectiveFlag == "niaEmailNeeded" && <NiaEmailNeededDialog />}

      {/* Chybi 2FA */}
      {effectiveFlag == "twoAuthNeeded" && <TwoAuthLoginDialog />}
    </LandingPageLayout>
  );
};
