import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import {
  AlertsContext,
  DistaContent,
  DistaPageHeaderProps,
} from "@sista/dista-ui";
import { IdmClientContext, Relation } from "@sista/idm-client";
import { Right, UiContext, useSafeSistaAsync } from "@sista/library-stasi";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";
import { ConfigContext } from "../../../hooks/ConfigContext";
import { OrganizationEditDialog } from "../../dialogs/organization/OrganizationEditDialog";
import { OrganizationInviteDialog } from "../../dialogs/organization/OrganizationInviteDialog";
import { OrganizationProjectRolesDialog } from "../../dialogs/organization/OrganizationProjectRolesDialog";
import { OrganizationRolesDialog } from "../../dialogs/organization/OrganizationRolesDialog";
import { TeamStats } from "../../screens/department/TeamStats";

type Filter = "all" | "active" | "blocked" | "invited";
const allFilter = ["all", "active", "blocked", "invited"] as Filter[];

export const OrganizationUsersContent = () => {
  const { openDialog, openedDialog, openedDialogData } = useContext(UiContext);
  const idm = useContext(IdmClientContext);
  const { filterProfileRoles, roleName } = useContext(ConfigContext);
  const alerts = useContext(AlertsContext);
  const rejstrikClient = useRejstrikClient();
  const { t, i18n } = useTranslation("ucet");
  const { organizationId } = useParams();
  const [filter, setFilter] = useState("all" as Filter);
  const organizationGenerichKey = organizationId.replace(":", ".");
  const organizationGenerichCode = organizationGenerichKey.split(".")[1];
  const lang = i18n.language;

  const [teamCall, teamState] = useSafeSistaAsync(
    rejstrikClient.getOrganizationTeam
  );

  const filterLabels = {
    all: t("ucet.organization.filter.all", "Všichni"),
    active: t("ucet.organization.filter.active", "Aktivní"),
    blocked: t("ucet.organization.filter.blocked", "Zablokovaní"),
    invited: t("ucet.organization.filter.invited", "Pozvaní"),
  };

  const openInviteDialog = useCallback(
    () =>
      openDialog("organizationInvitePerson", {
        organizationId: organizationId,
      }),
    [openDialog, organizationId]
  );

  const refresh = useCallback(() => {
    if (organizationId && idm.roles) {
      teamCall({
        organizationId: organizationId,
        pageSize: 1000,
      });
    }
  }, [organizationId, idm.roles, teamCall]);

  function dummy(str: string): any {
    return null;
  }

  /*  const [deactivateProfileCall] = useSafeSistaAsync(
    useCallback(
      async (id: string) => {
        await rejstrikClient.disableProfileInOrganization({
          profileId: id,
          organizationId: organizationId,
          changeReason: "blocked from z /ucet",
          minorChange: false,
          data: id,
        });
        alerts.showSuccess(
          t("ucet.organization.profile.disabledResult", "Profil byl deaktivován")
        );
        refresh();
      },
      [rejstrikClient, organizationId, alerts, t, refresh]
    )
  ); */

  /* const [reactivateProfileCall] = useSafeSistaAsync(
    useCallback(
      async (id: string) => {
        await rejstrikClient.enableProfileInOrganization({
          profileId: id,
          organizationId: organizationId,
          changeReason: "Aktivace z UI",
          minorChange: false,
          data: id,
        });
        alerts.showSuccess(
          t("ucet.organization.profile.disabledResult", "Profil byl aktivován")
        );
        refresh();
      },
      [rejstrikClient, organizationId, alerts, t, refresh]
    )
  ); */

  const profileStateRenderer = useCallback(
    (params: GridRenderCellParams) => {
      const p = params.row;
      if (p.dateDisabled != null) {
        return (
          <Typography variant="body1" color="text.disabled">
            {t("ucet.organization.profile.statusDisabled", "Zablokován")}
          </Typography>
        );
      }
      if (p.lastSeen == null) {
        return (
          <Typography variant="body1" color="warning.main">
            {/* {t("ucet.organization.profile.statusInvited", "Pozvánka poslána")} */}
          </Typography>
        );
      }
      return (
        <Typography variant="body1" color="primary.main">
          {t("ucet.organization.profile.statusActive", "Aktivní")}
        </Typography>
      );
    },
    [t]
  );

  const rolesRenderer = useCallback(
    (params: GridRenderCellParams) => {
      const rs = params.row._viewData.relations as Relation[];

      return (
        <Tooltip
          title={rs
            .filter((r) => r.refEntityId == organizationId)
            .map((r) => roleName(r.role, lang))
            .join(", ")}
        >
          <span>
            {rs
              .filter((r) => r.refEntityId == organizationId && r.to == null)
              .map((r) => (
                <span key={r.id}>
                  <Chip label={roleName(r.role, lang)} sx={{ mr: 1 }} />{" "}
                </span>
              ))}
          </span>
        </Tooltip>
      );
    },
    [lang, roleName, organizationId]
  );

  // screen starts here
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refresh, [organizationId]);

  const rows = useMemo(() => {
    let r = teamState.value ?? [];
    if (filter === "active") {
      r = r.filter((p) => p.dateDisabled == null && p.lastSeen != null);
    } else if (filter === "blocked") {
      r = r.filter((p) => p.dateDisabled != null);
    } else if (filter === "invited") {
      r = r.filter((p) => p.dateDisabled == null && p.lastSeen == null);
    } else {
      // all
    }
    return r;
  }, [teamState.value, filter]);

  const columns = useMemo(() => {
    return [
      {
        field: "picture",
        headerName: "",
        sortable: false,
        width: 48,
        renderCell: (params: GridRenderCellParams) => {
          const p = params.row;
          return (
            <Avatar
              src={p.profilePic}
              alt={p.displayName}
              sx={{ width: 32, height: 32 }}
            />
          );
        },
      },
      {
        field: "firstName",
        headerName: t("ucet.organization.table.firstName", "Jméno"),
        sortable: true,
        flex: 1,
        width: 100,
      },
      {
        field: "lastName",
        headerName: t("ucet.organization.table.lastName", "Příjmení"),
        sortable: true,
        flex: 1,
        width: 120,
      },
      {
        field: "email",
        headerName: t("ucet.organization.table.email", "E-mail"),
        sortable: true,
        flex: 1,
        width: 100,
      },
      {
        field: "phone",
        headerName: t("ucet.organization.table.phone", "Telefon"),
        sortable: true,
        flex: 1,
        width: 100,
      },
      {
        field: "roles",
        headerName: t("ucet.organization.table.role", "Role / Zapojení"),
        sortable: false,
        renderCell: rolesRenderer,
        width: 450,
      },
      /* {
        field: "section",
        headerName: t("ucet.organization.table.section", "Útvar"),
        valueGetter: (params: GridValueGetterParams) => {
          const d =
            params.row._viewData?.d[organizationGenerichCode]?.details ?? "";
          return [d?.section, d?.info].filter(Boolean).join(" / ");
        },
        sortable: true,
        flex: 2,
        width: 200,
      }, */
      {
        field: "stats",
        headerName: t("ucet.organization.table.status", "Stav"),
        sortable: false,
        renderCell: profileStateRenderer,
        width: 150,
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params: any) =>
          [
            <GridActionsCellItem
              key={"edit"}
              icon={<EditIcon />}
              label={t("ucet.organization.table.editAction", "Upravit")}
              onClick={() => openDialog("organizationEditProfile", params.row)}
            />,
            /* params.row.dateDisabled == null && (
              <GridActionsCellItem
                key={"deactivate"}
                icon={<LockPersonIcon />}
                label={t("ucet.organization.table.lockAction", "Zablokovat")}
                onClick={() => {
                  deactivateProfileCall(params.row.id!);
                }}
                showInMenu
              />
            ),
            params.row.dateDisabled != null && (
              <GridActionsCellItem
                key={"reactivate"}
                icon={<LockOpenIcon />}
                label={t("ucet.organization.table.unlockAction", "Odemknout")}
                onClick={() => reactivateProfileCall(params.row.id!)}
                showInMenu
              />
            ), */
            params.row.dateDisabled == null && (
              <GridActionsCellItem
                key={"roles"}
                icon={<AdminPanelSettingsIcon />}
                label={t(
                  "ucet.organization.table.rolesAction",
                  "Nastavení rolí uživatele"
                )}
                onClick={() => openDialog("organizationEditRoles", params.row)}
                showInMenu
              />
            ),
            <GridActionsCellItem
              key={"roles"}
              icon={<AdminPanelSettingsIcon />}
              label={t(
                "ucet.organization.table.projectRolesAction",
                "Přehled zapojení uživatele"
              )}
              onClick={() =>
                openDialog("organizationViewProjectRoles", params.row)
              }
              showInMenu
            />,
            // params.row.lastSeen == null && params.row.dateDisabled == null && (
            //   <GridActionsCellItem
            //     key={"resend"}
            //     icon={<ReplayIcon />}
            //     label={t(
            //       "ucet.organization.table.resendAction",
            //       "Znovu poslat pozvánku"
            //     )}
            //     onClick={dummy(params.row.id!)}
            //     showInMenu
            //   />
            // ),
            // params.row.lastSeen != null && (
            //   <GridActionsCellItem
            //     key={"transfer"}
            //     icon={<SwapHorizIcon />}
            //     label={t(
            //       "ucet.organization.table.transferAction",
            //       "Předat projekty jinému uživateli"
            //     )}
            //     onClick={dummy(params.row.id!)}
            //     showInMenu
            //   />
            // ),
          ].filter(Boolean),
      },
    ];
  }, [
    t,
    rolesRenderer,
    profileStateRenderer,
    openDialog,
    //organizationGenerichCode,
  ]);

  const headerConfig: DistaPageHeaderProps = {
    breadcrumbs: [
      /* {
        title: t("ucet.menu.breadcrumbs.home", "Domů"),
        action: () => (window.location.href = "/ucet/"),
      },
      {
        title: t("ucet.organization.adminTitle", "Správa organizace"),
      }, */
    ],
    pageTitle: t("ucet.organization.users.title", "Uživatelé"),
    chipsDefinition: [
      //{label: t('chips.approved', 'Approved'), color: 'primary'},
    ],
    actions: [
      {
        title: t("ucet.organization.users.buttonExport", "Exportovat"),
        action: () => {
          //
        },
      },
      {
        title: t("ucet.organization.users.buttonInvite", "Pozvat uživatele"),
        action: () => openInviteDialog(),
        primary: true,
      },
      // {
      //     title: t('mainpage.actions.primary', 'Primary action'),
      //     action: () => alert('Primary action clicked'),
      //     primary: true,
      // },
      // {title: t('mainpage.actions.action1', 'First action'), action: () => alert('First action clicked')},
      // {title: t('mainpage.actions.action2', 'Second action'), action: () => alert('Second action clicked')},
    ],
  };

  return (
    <>
      <Stack direction={"column"} spacing={2}>
        <Right sx={{ my: 2 }}>
          <Button variant="contained" onClick={() => openInviteDialog()}>
            {t("ucet.organization.users.buttonInvite", "Pozvat uživatele")}
          </Button>
        </Right>
        <Stack
          direction={"row"}
          sx={{ backgroundColor: "secondary.light", p: 1 }}
        >
          <FilterListIcon sx={{ mr: 2 }} fontSize={"medium"} />
          {allFilter.map((f) => {
            return (
              <Chip
                color={f === filter ? "primary" : "default"}
                key={f}
                label={filterLabels[f]}
                onClick={() => setFilter(f)}
                sx={{ mr: 1 }}
              />
            );
          })}
        </Stack>

        <Box sx={{ flexGrow: 1 }}>
          <DataGrid columns={columns} pageSizeOptions={[100]} rows={rows} />
        </Box>
        <TeamStats team={teamState.value} />
      </Stack>
      {openedDialog == "organizationInvitePerson" && (
        <OrganizationInviteDialog
          organizationId={organizationId}
          onDone={refresh}
        />
      )}
      {openedDialog == "organizationEditRoles" && (
        <OrganizationRolesDialog
          organizationId={organizationId}
          profile={openedDialogData}
          onDone={refresh}
        />
      )}
      {openedDialog == "organizationViewProjectRoles" && (
        <OrganizationProjectRolesDialog
          profile={openedDialogData}
          onDone={refresh}
        />
      )}
      {openedDialog == "organizationEditProfile" && (
        <OrganizationEditDialog
          organizationId={organizationId}
          profile={openedDialogData}
          onDone={refresh}
        />
      )}
    </>
  );
};
