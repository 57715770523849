import { DialogContent, Stack, Typography } from "@mui/material";
import { DistaDialog, DistaDialogHeader } from "@sista/dista-ui";
import {
  LoadingGuard,
  Organization,
  OrganizationType,
  Right,
  SmartButton,
  UiContext,
  useDebounce,
  useEmailValidation,
  usePhoneNumberValidation,
  useRequiredValidation,
  useSafeSistaAsync,
  useUrlValidation,
} from "@sista/library-stasi";
import { useDicValidation } from "@sista/library-stasi/dist/components/form/validations/useDicValidation";
import { useIcoValidation } from "@sista/library-stasi/dist/components/form/validations/useIcoValidation";
import { useCallback, useContext, useMemo } from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";

export type OrganizationNewUnitDialogProps = {
  organizationId: string;
  onDone: () => unknown;
};

export const OrganizationNewUnitDialog = (
  props: OrganizationNewUnitDialogProps
) => {
  const { t } = useTranslation("ucet");
  const { closeDialogs } = useContext(UiContext);
  const form = useForm<Organization>({
    defaultValues: {
      parentId: props.organizationId,
      type: "PART",
    },
  });
  const rej = useRejstrikClient();
  const required = useRequiredValidation();
  const ico = useIcoValidation();
  const dic = useDicValidation();
  const phone = usePhoneNumberValidation();
  const url = useUrlValidation();
  const email = useEmailValidation();
  const [createOrganizationUnitCall, createOrganizationUnitState] =
    useSafeSistaAsync(rej.createOrganizationUnit);

  const organizationId = useMemo(
    () => props.organizationId,
    [props.organizationId]
  );

  const organizationTypeOpts: { id: OrganizationType; label: string }[] =
    useMemo(() => {
      return [
        {
          id: "UNIVERSITY",
          label: t("ucet.organization.units.type.university", "Univerzita"),
        },
        {
          id: "FACULTY",
          label: t("ucet.organization.units.type.faculty", "Fakulta"),
        },
        {
          id: "DEPARTMENT",
          label: t("ucet.organization.units.type.department", "Katedra"),
        },
        {
          id: "COMPANY",
          label: t("ucet.organization.units.type.company", "Firma"),
        },
        {
          id: "RESEARCH_AGENCY",
          label: t(
            "ucet.organization.units.type.researchAgency",
            "Výzkumná agentura"
          ),
        },
        {
          id: "MINISTRY",
          label: t("ucet.organization.units.type.ministry", "Ministerstvo"),
        },
        {
          id: "PART",
          label: t("ucet.organization.units.type.part", "Organizační jednotka"),
        },
      ];
    }, [t]);

  const createUnitHanler = useCallback(
    async (data: Organization) => {
      await createOrganizationUnitCall({
        organizationId: organizationId,
        displayName: data.displayName,
        officialWebPage: data.officialWebPage,
        idNumber: data.idNumber,
        vatId: data.vatId,
        type: data.type,
        parentId: organizationId,
        code: data.code,
        email: data.email,
        phone: data.phone,
        officialAddress: data.officialAddress,
        contactAddress: data.contactAddress,
        idDataBox: data.idDataBox,
        entityType: "organization",
        entitySubType: data.type,
      });
      props.onDone();
      closeDialogs();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onDone, closeDialogs, organizationId, createOrganizationUnitCall]
  );

  /* const loading = updateOrganizationState.pending ?? false;
  // pending na true se nastavi hned, pending na false az po 500ms
  // tudis se tlacitko bude tocit vzdy minimalne 500ms
  const debouncedPending = useDebounce(loading, loading ? 0 : 500);

  const primaryActionProps = useMemo(
    () => ({
      children: t("ucet.organization.units.save", "Uložit"),
      onClick: () => updateUnitHanler(),
      primary: true,
      loading: loading || debouncedPending,
      disabled: false,
    }),
    [form, debouncedPending, updateUnitHanler, loading, t]
  ); */

  return (
    <DistaDialog open={true} onClose={closeDialogs}>
      <DistaDialogHeader
        onClose={closeDialogs}
        title={t(
          "ucet.organization.units.addNewUnit",
          "Přidání organizační jednotky"
        )}
      />
      <DialogContent>
        <FormContainer formContext={form} onSuccess={createUnitHanler}>
          <Stack spacing={2} sx={{ my: 2 }}>
            <TextFieldElement
              name="displayName"
              label={t("ucet.organization.units.name", "Název")}
              validation={{ ...required }}
            />
            <TextFieldElement
              name="officialWebPage"
              label={t(
                "ucet.organization.units.officialWebPage",
                "Adresa oficiální webové stránky"
              )}
              validation={{ ...url }}
            />
            <TextFieldElement
              name="idDataBox"
              label={t(
                "ucet.organization.units.idDataBox",
                "ID datové schránky"
              )}
            />
            <TextFieldElement
              name="email"
              label={t("ucet.organization.units.email", "Email")}
              validation={{ ...email }}
            />
            <TextFieldElement
              name="phone"
              label={t("ucet.organization.units.phone", "Telefon")}
              validation={{ ...phone }}
            />
            {/* <TextFieldElement
              name="idNumber"
              label={t("ucet.organization.units.ic", "IČ")}
              validation={{ ...ico }}
            />
            <TextFieldElement
              name="vatId"
              label={t("ucet.organization.units.dic", "DIČ")}
              validation={{ ...dic }}
            /> */}
            <SelectElement
              name="type"
              label={t("ucet.organization.units.type", "Typ")}
              options={organizationTypeOpts}
            />
            <TextFieldElement
              name="parentId"
              label={t(
                "ucet.organization.units.parent",
                "ID Nadřazené jednotky"
              )}
              disabled={true}
            />
            <TextFieldElement
              name="code"
              label={t("ucet.organization.units.code", "Kód")}
              validation={{}}
            />
            {/* <AddressForm name={"officialAddress"} />
            <AddressForm name={"contactAddress"} /> */}
            <Right>
              <SmartButton state={createOrganizationUnitState}>
                {t(
                  "ucet.organization.units.buttonNew",
                  "Přidat organizační složku"
                )}
              </SmartButton>
            </Right>
          </Stack>
        </FormContainer>
      </DialogContent>
    </DistaDialog>
  );
};

export type AddressType = {
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  zip?: string | null;
};

type AddressFormProps = {
  name: string;
};

const AddressForm = (props: AddressFormProps) => {
  const { t } = useTranslation("ucet");

  return (
    <>
      <Typography variant="h6">
        {t("ucet.organization.units." + props.name + ".title", props.name)}
      </Typography>
      <Stack spacing={2} sx={{ my: 2 }}>
        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
          <TextFieldElement
            name={`${props.name}.street`}
            label={t("ucet.organization.units.address.street", "Ulice")}
            sx={{ width: "70%" }}
          />
          <TextFieldElement
            name={`${props.name}.streetNumber`}
            label={t(
              "ucet.organization.units.address.streetNumber",
              "Číslo popisné"
            )}
            sx={{ width: "30%" }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextFieldElement
            name={`${props.name}.zip`}
            label={t("ucet.organization.units.address.zip", "PSČ")}
            sx={{ width: "30%" }}
          />
          <TextFieldElement
            name={`${props.name}.city`}
            label={t("ucet.organization.units.address.city", "Město")}
            sx={{ width: "70%" }}
          />
        </Stack>
      </Stack>
    </>
  );
};
