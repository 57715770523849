import { Stack } from "@mui/material";
import { AlertsContext } from "@sista/dista-ui";
import {
  normalizePhoneNumber,
  Profile,
  Right,
  SistaDialog,
  SistaFormHeader,
  SmartButton,
  UiContext,
  useEmailValidation,
  useMaxLengthValidation,
  useMinLengthValidation,
  useNameValidation,
  usePhoneNumberValidation,
  useRequiredValidation,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";

export type OrganizationEditForm = Profile & {
  section?: string;
  info?: string;
  tempDate?: unknown;
};

export type OrganizationEditDialogProps = {
  organizationId: string;
  profile: Profile;
  onDone: () => unknown;
};

export const OrganizationEditDialog = (props: OrganizationEditDialogProps) => {
  const { closeDialogs } = useContext(UiContext);
  const alerts = useContext(AlertsContext);

  //const organizationGenerichKey = props.organizationId.replace(":", ".");
  //const organizationGenerichCode = organizationGenerichKey.split(".")[1];

  /* const storedSection = (props.profile as any)._viewData?.d[
    organizationGenerichCode
  ]?.details?.section;
  const storedInfo = (props.profile as any)._viewData?.d[
    organizationGenerichCode
  ]?.details?.info; */

  const form = useForm<OrganizationEditForm, unknown>({
    defaultValues: {
      ...props.profile,
      /* section: storedSection,
      info: storedInfo, */
    },
  });
  const { t } = useTranslation("ucet");

  const phone = usePhoneNumberValidation();
  const email = useEmailValidation();
  //const maxLength = useMaxLengthValidation(37);
  const maxInfoLength = useMaxLengthValidation(1000);
  //const minLength = useMinLengthValidation(2);
  //const required = useRequiredValidation();
  //  const reasonableDate = useReasonableDateValidation();
  //  const email = useEmailValidation();
  const nameValidation = useNameValidation();

  const { updateProfileInOrganization, setCustomDataToProfileInOrganization } =
    useRejstrikClient();

  const updateProfileImpl = useCallback(
    async (formData: OrganizationEditForm) => {
      formData.phone = normalizePhoneNumber(formData.phone);
      await updateProfileInOrganization({
        organizationId: props.organizationId,
        profileId: props.profile.id,
        changeReason: "update from /ucet",
        minorChange: false,
        data: formData,
      });
      /* const details = {
        ...((props.profile as any)._viewData.d[organizationGenerichKey]
          ?.details ?? {}),
        section: formData.section,
        info: formData.info,
      }; */
      /* if (formData.section != storedSection || formData.info != storedInfo) {
        await setCustomDataToProfileInOrganization({
          organizationId: props.organizationId,
          profileId: props.profile.id,
          generichKey: props.organizationId.replace(":", ".") + ".details",
          changeReason: "update from /ucet",
          minorChange: false,
          data: details,
        }).catch((e: unknown) => {
          console.error(e);
          alerts.showError(
            t(
              "ucet.organization.errorNoDetails",
              "Detailnější informace se nepodařilo uložit, doplňte je prosím později."
            )
          );
        });
      } */
      alerts.showSuccess(
        t("ucet.organization.profileUpdated", "Profil byl upraven")
      );
      props.onDone();
      closeDialogs();
    },
    [
      alerts,
      closeDialogs,
      //organizationGenerichKey,
      props,
      //setCustomDataToProfileInOrganization,
      //storedSection,
      t,
      updateProfileInOrganization,
      //storedInfo,
    ]
  );

  const [updateProfileCall, updateProfileState] =
    useSafeSistaAsync(updateProfileImpl);

  return (
    <SistaDialog
      title={t("ucet.organization.editDialogTitle", "Změna vybraných údajů")}
      onClose={closeDialogs}
    >
      <SistaFormHeader
        title={t("ucet.organization.editTitle", "Změna profilu")}
        subtitle={t(
          "ucet.organization.editSubtitle",
          "O případné další změny požádejte administrátora SISTA."
        )}
      />

      <FormContainer onSuccess={updateProfileCall} formContext={form}>
        <Stack direction="column" spacing={2}>
          {/* <SelectElement
            name="section"
            options={OrganizationUnitsOpts}
            label={t("ucet.organization.form.section", "Organizační jednotka")}
            validation={{
              ...maxLength,
              ...minLength,
            }}
          /> */}

          {/* <TextFieldElement
            name="info"
            autoComplete={"off"}
            label={t("ucet.organization.form.info", "Doplňující informace")}
            validation={{
              ...maxInfoLength,
            }}
          /> */}

          <TextFieldElement
            name="phone"
            placeholder={t(
              "ucet.organization.form.phonePlaceholder",
              "999 999 999"
            )}
            autoComplete={"off"}
            label={t("ucet.organization.form.phone", "Kontaktní telefon")}
            validation={{
              ...phone,
            }}
          />

          <TextFieldElement
            name="email"
            autoComplete={"off"}
            label={t("ucet.organization.form.email", "Kontaktní email")}
            validation={{
              ...email,
            }}
          />

          <Right sx={{ marginTop: 4 }}>
            <SmartButton state={updateProfileState}>
              {t("ucet.organization.buttonEdit", "Uložit změny")}
            </SmartButton>
          </Right>
        </Stack>
      </FormContainer>
    </SistaDialog>
  );
};
