import { Info } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  LoadingGuard,
  Right,
  UiContext,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";
import {
  AddressType,
  OrganizationNewUnitDialog,
} from "../../../components/dialogs/organization/OrganizationNewUnitDialog";

export const OrganizationUnitsContent = () => {
  const { t } = useTranslation();

  const rej = useRejstrikClient();

  const { organizationId } = useParams();

  const navigate = useNavigate();

  const { openDialog, openedDialog, openedDialogData } = useContext(UiContext);

  const [getOrganizationUnitsCall, getOrganizationUnitsState] =
    useSafeSistaAsync(rej.getAllOrganizationUnits);

  const refresh = useCallback(() => {
    if (organizationId) {
      getOrganizationUnitsCall({ organizationId: organizationId });
    }
  }, [organizationId, getOrganizationUnitsCall]);

  useEffect(refresh, [refresh, organizationId]);

  const getAddress = (address: AddressType) => {
    console.log("sidlo ", address);
    if (address == null) return "-";
    return (
      address.street +
      " " +
      address.streetNumber +
      ", " +
      address.zip +
      " " +
      address.city
    );
  };

  const rows = useMemo(() => {
    if (getOrganizationUnitsState.value == null) {
      return [];
    }
    return getOrganizationUnitsState.value.map((unit) => {
      return {
        id: unit.id,
        displayName: unit.displayName,
        residenceAddress: getAddress(unit.officialAddress),
      };
    });
  }, [getOrganizationUnitsState.value]);

  const columns = useMemo(() => {
    return [
      {
        field: "displayName",
        headerName: t("ucet.organization.units.table.name", "Označení"),
        sortable: true,
        flex: 1,
        width: 100,
      },
      {
        field: "residenceAddress",
        headerName: t("ucet.organization.units.table.residentAdress", "Sídlo"),
        sortable: true,
        flex: 1,
        width: 120,
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params: any) => [
          <GridActionsCellItem
            key={"edit"}
            icon={<Info />}
            label={t("ucet.organization.units.table.detail", "Detail")}
            onClick={() => navigate("/sprava-organizace/" + params.row.id)}
          />,
        ],
      },
    ];
  }, [t, navigate]);

  return (
    <LoadingGuard state={getOrganizationUnitsState}>
      {getOrganizationUnitsState.value && (
        <Stack direction={"column"} spacing={2} sx={{ my: 2 }}>
          <Right>
            <Button
              variant="contained"
              onClick={() => {
                openDialog("organizationNewUnit");
              }}
            >
              {t("ucet.organization.units.buttonAdd", "Přidat")}
            </Button>
          </Right>
          <Box sx={{ flexGrow: 1 }}>
            <DataGrid
              columns={columns}
              pageSizeOptions={[100]}
              rows={
                rows as {
                  id: string;
                  displayName: string;
                  residenceAddress: string;
                }[]
              }
              sx={{ minHeight: "400px" }}
            />
          </Box>
        </Stack>
      )}
      {openedDialog === "organizationNewUnit" && (
        <OrganizationNewUnitDialog
          organizationId={organizationId}
          onDone={refresh}
        />
      )}
    </LoadingGuard>
  );
};
