import { ContentCopy } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { AlertsContext, DistaInfoListBlock } from "@sista/dista-ui";
import {
  Organization,
  SmartButton,
  UiContext,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";
import { detailItem } from "../../../utils/detailitem";

type OrganizationDetailInfoContentProps = {
  organization: Organization;
};

type Address = {
  street?: string;
  streetNumber?: string;
  city?: string;
  zip?: string;
};

export const buildAddress = (address: Address) => {
  if (address == null) return "-";
  return (
    <Stack direction={"column"} spacing={0}>
      <Stack
        direction={"row"}
        spacing={1}
        sx={{ alignItems: "center", justifyContent: "end" }}
      >
        <Typography variant="body1">
          {address.street ? address.street : address.city ? address.city : ""}
        </Typography>
        <Typography variant="body1">
          {address.streetNumber ? " " + address?.streetNumber : ""}
        </Typography>
      </Stack>

      {/* <Stack
        direction={"row"}
        spacing={1}
        sx={{ alignItems: "center", justifyContent: "end" }}
      >
        <Typography variant="body1">
          {address.zip ? address.zip : ""}
        </Typography>
        <Typography variant="body1">
          {address.city ? " " + address.city : ""}
        </Typography>
      </Stack> */}
      <Typography variant="body1">
        {address.city ? " " + address.city : ""}
      </Typography>
      <Typography variant="body1">{address.zip ? address.zip : ""}</Typography>
    </Stack>
  );
};

export const OrganizationDetailInfoContent = (
  props: OrganizationDetailInfoContentProps
) => {
  const { t } = useTranslation();
  const { showInfo } = useContext(AlertsContext);

  const rej = useRejstrikClient();

  const [refreshMagnusWebCall, refreshMagnusWebState] = useSafeSistaAsync(
    rej.refreshMagnusWebReport
  );

  const [getOrganizationCustomDataCall, getOrganizationCustomDataState] =
    useSafeSistaAsync(rej.getOrganizationCustomData);

  const { refresh } = useContext(UiContext);

  useEffect(() => {
    getOrganizationCustomDataCall({ organizationId: props.organization.id });
  }, [props.organization.id, getOrganizationCustomDataCall]);

  const organizationCustomData = useMemo(() => {
    if (getOrganizationCustomDataState.value) {
      return getOrganizationCustomDataState.value;
    }
    return {};
  }, [getOrganizationCustomDataState.value]);

  const refreshOrganizationData = useCallback(async () => {
    const result = await refreshMagnusWebCall({
      organizationId: props.organization.id,
    });
    if (typeof result === "object" && result) {
      showInfo(
        t("ucet.organization.info.refreshed", "Data byla aktualizována")
      );
      refresh();
    }
  }, [t, refreshMagnusWebCall, showInfo, refresh, props.organization.id]);

  const contactData = useMemo(() => {
    const copyToClipboard = (text: string) => {
      showInfo(t("ucet.organization.info.copied", "Zkopírováno"));
      navigator.clipboard.writeText(text);
    };

    return {
      label: (
        <Stack direction="row">
          <Typography variant={"h3Hl"} sx={{ flexGrow: 1 }}>
            {t("ucet.organization.info.contact.title", "Kontaktní údaje")}
          </Typography>
          {/* <SmartButton
            variant="text"
            state={refreshMagnusWebState}
            onClick={() => refreshOrganizationData()}
          >
            {t("common.refreshMagnus", "Aktualizovat")}
          </SmartButton> */}
        </Stack>
      ),
      infoListItems: [
        detailItem(
          t("ucet.organization.info.contact.idDataBox", "ID datové schránky"),
          props.organization.idDataBox ? (
            <Stack
              direction={"row"}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "end" }}
            >
              {props.organization.idDataBox}
              <Button
                variant="text"
                onClick={() => {
                  copyToClipboard(props.organization.idDataBox);
                }}
              >
                <ContentCopy />
              </Button>
            </Stack>
          ) : (
            t("ucet.organization.info.contact.noDataBox", "-")
          )
        ),
        /* detailItem(
          t("ucet.organization.info.contact.email", "Email"),
          props.organization.email ? (
            <Stack
              direction={"row"}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "end" }}
            >
              {props.organization.email}
              <Button
                variant="text"
                onClick={() => {
                  copyToClipboard(props.organization.email);
                }}
              >
                <ContentCopy />
              </Button>
            </Stack>
          ) : (
            t("ucet.organization.info.contact.noEmail", "-")
          )
        ), */
        detailItem(
          t("ucet.organization.info.contact.residenceAddress", "Sídlo"),
          buildAddress(props.organization.officialAddress)
        ),
        /* detailItem(
          t("ucet.organization.info.contact.address", "Adresa"),
          buildAddress(props.organization.contactAddress)
        ), */
      ],
    };
  }, [
    //refreshOrganizationData,
    //refreshMagnusWebState,
    t,
    showInfo,
    props.organization.idDataBox,
    //props.organization.email,
    //props.organization.contactAddress,
    props.organization.officialAddress,
  ]);
  // TODO - zatim nemam data ze serveru, tak tu jsou testovaci data - musi pryc
  const organizationMembers = useMemo(() => {
    return (organizationCustomData as any)?.rejstrik?.representation?.board
      ?.members;
  }, [organizationCustomData]);

  const executiveData = useMemo(() => {
    return (
      <Stack direction={"column"} spacing={2} sx={{ mx: 2 }}>
        <Typography variant="h5">
          {t("ucet.organization.info.executive.title", "Statutární orgán")}
        </Typography>
        {organizationMembers ? (
          organizationMembers.map((member: any) => {
            return (
              <Stack direction={"column"} key={member.id}>
                <Stack direction={"row"} spacing={2} sx={{}}>
                  <Typography variant="body1Hl" sx={{ fontWeight: "bold" }}>
                    {member.type ? member.type : "-"}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography variant="body1Hl" sx={{ fontWeight: "bold" }}>
                    {member.firstName ? member.firstName : ""}{" "}
                    {member.lastName ? member.lastName : "-"}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={2} sx={{}}>
                  <Typography variant="body1" sx={{}}>
                    {t(
                      "ucet.organization.info.executive.birthDate",
                      "Datum narození:"
                    )}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography variant="body1" sx={{}}>
                    {member.birthDate ? member.birthDate : "-"}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={2} sx={{}}>
                  <Typography variant="body1" sx={{}}>
                    {t(
                      "ucet.organization.info.executive.funcitonDate",
                      "Den vznikufunkce:"
                    )}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography variant="body1" sx={{}}>
                    {member.from ? member.from : "-"}
                  </Typography>
                </Stack>
              </Stack>
            );
          })
        ) : (
          <Typography variant="body1" sx={{}}>
            {
              (t(
                "ucet.organization.info.organizationMembers.notFound",
                "Nenalezeno"
              ),
              "")
            }
          </Typography>
        )}
      </Stack>
    );
  }, [t, organizationMembers]);

  return (
    <Paper variant="infoCard" sx={{ mt: 2 }}>
      <Grid container infoCardMultiColumns>
        <Grid item md={6} xs={12}>
          <Stack direction={"column"} spacing={2} sx={{ alignItems: "center" }}>
            <Typography variant="h3Hl">
              {props.organization.displayName}
            </Typography>
            <Typography variant="body1">
              {t("ucet.organization.info.ico", "IČO") +
                " " +
                props.organization.idNumber ?? "-"}
            </Typography>
            {/* {officialAddress} */}
          </Stack>
          <Divider variant="middle" sx={{ mt: 2 }} />
          <DistaInfoListBlock {...contactData} />
        </Grid>
        <Grid item md={6} xs={12}>
          {executiveData}
        </Grid>
      </Grid>
    </Paper>
  );
};
