import { Stack, Typography } from "@mui/material";
import { AlertsContext } from "@sista/dista-ui";
import {
  ApplicantDTO,
  Dual,
  LoadingGuard,
  Right,
  SistaDialog,
  SistaFormHeader,
  SistaPlainDateElement,
  SmartButton,
  UiContext,
  useDateValidation,
  useEmailValidation,
  useMaxLengthValidation,
  useMinLengthValidation,
  useNameValidation,
  useReasonableDateValidation,
  useRequiredValidation,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import {
  FormContainer,
  RadioButtonGroup,
  TextFieldElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";

type OrganizationInviteForm = ApplicantDTO & {
  section?: string;
  tempDate?: unknown;
  role?: string;
  profileType?: string;
};

type OrganizationInviteDialogProps = {
  organizationId: string;
  onDone: () => any;
};

export const OrganizationInviteDialog = (
  props: OrganizationInviteDialogProps
) => {
  const { closeDialogs, refresh } = useContext(UiContext);
  const alerts = useContext(AlertsContext);
  const [withoutAccount, setWithoutAccount] = useState(false);
  const [showSimilarPersonDialog, setShowSimilarPersonDialog] = useState(false);

  const form = useForm<OrganizationInviteForm, any>({
    defaultValues: {
      organizationId: props.organizationId,
      profileType: withoutAccount ? "true" : "false",
    },
  });
  const { t } = useTranslation("ucet");

  const required = useRequiredValidation();
  const email = useEmailValidation();
  const nameValidation = useNameValidation();
  const maxLength = useMaxLengthValidation(37);
  const minLength = useMinLengthValidation(2);
  const date = useDateValidation();
  const birth = useReasonableDateValidation();

  const rej = useRejstrikClient();

  const [checkIsSimilarPersonCall, checkIsSimilarPersonState] =
    useSafeSistaAsync(rej.getSimilarPersonEmail);

  const [sendInviteToSimilarPersonCall, sendInviteToSimilarPersonState] =
    useSafeSistaAsync(rej.iviteSimilarPerson);

  useEffect(() => {
    form.setValue("profileType", withoutAccount ? "true" : "false");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withoutAccount, form.setValue]);

  const createApplicantImpl = useCallback(
    async (formData: OrganizationInviteForm) => {
      //formData.phone = normalizePhoneNumber(formData.phone);
      const result = await rej.createOrganizationProfileAccount({
        ...formData,
        organizationId: props.organizationId,
        withoutAccount: withoutAccount,
      });
      const profileId = result.value!;
      console.log("ProfileId: " + profileId);
      alerts.showSuccess(
        t("ucet.organization.initationSent", "Pozvánka byla odeslána")
      );
      props.onDone();
      closeDialogs();
      refresh();
      return result;
    },
    [rej, alerts, t, props, closeDialogs, withoutAccount, refresh]
  );
  const [createApplicantCall, createApplicantState] =
    useSafeSistaAsync(createApplicantImpl);

  const checkIsSimilarPersonHandler = useCallback(
    async (formData: OrganizationInviteForm) => {
      const result = await checkIsSimilarPersonCall({
        ...formData,
        organizationId: props.organizationId,
        withoutAccount: withoutAccount,
      });
      if (result == null) {
        createApplicantCall(formData);
      }
      if (typeof result === "object" && result) {
        setShowSimilarPersonDialog(true);
      }
    },
    [
      createApplicantCall,
      setShowSimilarPersonDialog,
      checkIsSimilarPersonCall,
      props.organizationId,
      withoutAccount,
    ]
  );

  const sendInviteToSimilarPersonHandler = useCallback(async () => {
    const result = await sendInviteToSimilarPersonCall({
      value: checkIsSimilarPersonState.value.connectAccountConfirmationToken,
      organizationId: props.organizationId,
    });
    if (result) {
      alerts.showSuccess(
        t("ucet.organization.initationSent", "Pozvánka byla odeslána")
      );
    }
    if (!result) {
      alerts.showError(
        t(
          "ucet.organization.invitationFailed",
          "Uživatel je již přidán do organizace"
        )
      );
    }
    props.onDone();
    closeDialogs();
    refresh();
  }, [
    sendInviteToSimilarPersonCall,
    checkIsSimilarPersonState,
    props,
    alerts,
    t,
    closeDialogs,
    refresh,
  ]);

  /* const roles = useMemo(
    () =>
      filterProfileRoles("organizations").filter(
        (r) => r.role !== "ORGANIZATION_EMPLOYEE"
      ),
    [filterProfileRoles]
  ); */

  //console.log("organization ID: " + props.organizationId);

  return (
    <SistaDialog
      title={t(
        "ucet.organization.inviteDialogTitle",
        "Pozvat nového uživatele"
      )}
      onClose={closeDialogs}
    >
      {!showSimilarPersonDialog && (
        <>
          <SistaFormHeader
            title={t("ucet.organization.inviteTitle", "Pozvánka")}
            subtitle={t(
              "ucet.organization.inviteSubtitle",
              "Přizvěte další uživatele, kteří budou spolupracovat na projektech organizace."
            )}
          />
          <FormContainer
            onSuccess={checkIsSimilarPersonHandler}
            formContext={form}
          >
            <Stack direction="column" spacing={2}>
              <TextFieldElement
                name="email"
                autoComplete={"email"}
                label={t(
                  "ucet.organization.form.email",
                  "Pracovní e-mail uživatele"
                )}
                validation={{ ...required, ...email, ...maxLength }}
              />

              <TextFieldElement
                name="firstName"
                autoComplete={"off"}
                label={t("ucet.organization.form.firstName", "Křestní jméno")}
                validation={{
                  ...required,
                  ...maxLength,
                  ...minLength,
                  ...nameValidation,
                }}
              />
              <TextFieldElement
                name="lastName"
                autoComplete={"off"}
                label={t("ucet.organization.form.lastName", "Příjmení")}
                validation={{
                  ...required,
                  ...maxLength,
                  ...minLength,
                  ...nameValidation,
                }}
              />
              <SistaPlainDateElement
                name={"birthDate"}
                label={t("ucet.person.birthDate", "Datum narození")}
                validation={{ ...date, ...birth, ...required }}
              />
              {/* <SelectElement
            label={t("ucet.organization.form.role", "Role")}
            name="role"
            options={roles.map((r) => ({
              id: r.role,
              label: r.name[i18n.language],
            }))}
          /> */}

              <RadioButtonGroup
                name="profileType"
                options={[
                  {
                    id: "true",
                    label: t(
                      "ucet.organization.form.onlyProfile",
                      "Pouze profil"
                    ),
                  },
                  {
                    id: "false",
                    label: t(
                      "ucet.organization.form.withLogin",
                      "Včetně přihlášení"
                    ),
                  },
                ]}
                onChange={(e) => {
                  setWithoutAccount(e === "true");
                }}
              />

              <Right sx={{ marginTop: 4 }}>
                <SmartButton state={checkIsSimilarPersonState}>
                  {t("ucet.organization.buttonInvite", "Poslat pozvánku")}
                </SmartButton>
              </Right>
            </Stack>
          </FormContainer>
        </>
      )}
      {showSimilarPersonDialog && (
        <LoadingGuard state={checkIsSimilarPersonState}>
          <Typography variant={"body1"}>
            {t(
              "ucet.organization.connect.message",
              "Evidujeme účet s emailem:"
            )}
          </Typography>
          <Typography
            variant={"body1"}
            fontWeight={"bold"}
            textAlign={"center"}
            marginY={1}
          >
            {checkIsSimilarPersonState.value.anonymizedEmail}
          </Typography>
          <Typography variant={"body1"}>
            {t(
              "ucet.organization.connect.question",
              "Nejedná se o již existující SISTA účet?"
            )}
          </Typography>

          <Dual sx={{ marginTop: 6 }}>
            <SmartButton
              onClick={() =>
                createApplicantCall(form.getValues() as OrganizationInviteForm)
              }
              variant={"outlined"}
              state={createApplicantState}
            >
              {t(
                "ucet.organization.connect.cancelButton",
                "Ne, to není zvaný účet"
              )}
            </SmartButton>
            <SmartButton
              onClick={() => {
                sendInviteToSimilarPersonHandler();
              }}
              state={sendInviteToSimilarPersonState}
            >
              {t(
                "ucet.organization.connect.confirmButton",
                "Ano, to je zvaný účet"
              )}
            </SmartButton>
          </Dual>
        </LoadingGuard>
      )}
    </SistaDialog>
  );
};
