import { Divider, Stack } from "@mui/material";
import { DistaInfoListBlock } from "@sista/dista-ui";
import { Organization } from "@sista/library-stasi";
import { useTranslation } from "react-i18next";

export type OrganizationFindForm = {
  idNumber?: string;
};

export type OrganizationInfoContentProps = {
  organization: Organization;
};

export const OrganizationInfoContent = (
  props: OrganizationInfoContentProps
) => {
  const { t } = useTranslation("ucet");

  return (
    <>
      <Stack direction="column" spacing={1}>
        <DistaInfoListBlock
          label={t("ucet.organization.info.title", "Základní informace")}
          infoListItems={[
            {
              primaryColumn: t(
                "ucet.organization.info.name",
                "Úřední název subjektu"
              ),
              secondaryColumn: props.organization.displayName,
            },
            {
              primaryColumn: t("ucet.organization.info.idNumber", "IČ"),
              secondaryColumn: props.organization.idNumber,
            },
            {
              primaryColumn: t(
                "ucet.organization.info.idDataBox",
                "ID datové schránky"
              ),
              secondaryColumn: props.organization.idDataBox,
            },
          ]}
        ></DistaInfoListBlock>

        <Divider />

        <DistaInfoListBlock
          label={t("ucet.organization.info.officialAddress", "Sídlo")}
          infoListItems={[
            {
              primaryColumn: t(
                "ucet.organization.info.address.street",
                "Ulice"
              ),
              secondaryColumn: `${props.organization.officialAddress?.street} ${props.organization.officialAddress?.streetNumber}`,
            },
            {
              primaryColumn: t("ucet.organization.info.address.city", "Město"),
              secondaryColumn: props.organization.officialAddress?.city,
            },
            {
              primaryColumn: t("ucet.organization.info.address.zip", "PSČ"),
              secondaryColumn: props.organization.officialAddress?.zip,
            },
          ]}
        ></DistaInfoListBlock>
      </Stack>
    </>
  );
};
