import { Box } from "@mui/material";
import { IdmClientContext } from "@sista/idm-client";
import {
  AccountDTO,
  SistaDialog,
  UiContext,
  usePager,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { RegisterAccountStep1Form } from "./RegisterAccountStep1Form";
import { RegisterAccountStep2Form } from "./RegisterAccountStep2Form";
import { useIdmClient } from "../../../api/utils/useIdmClient";
import { IdmUiContext } from "../../../utils/IdmUiContext";
import ReCaptchaScript, { runReCaptcha } from "../../ui/ReCaptchaScript";
import { TextLanguageSwitcher } from "../../ui/TextLanguageSwitcher";

export type CreateAccountRequestForm = {
  accountDTO?: AccountDTO;
  consentChecked?: boolean;
  birthDate?: Date;
};

const exampleData = {} as CreateAccountRequestForm;

export const RegisterAccountDialog = () => {
  const { setLoggedAccount } = useContext(IdmClientContext);
  const ui = useContext(UiContext);
  const { t } = useTranslation("ucet");
  const { metadata } = useContext(IdmUiContext);

  const pager = usePager(0);

  const [registerFormData, setRegisterFormData] = useState(exampleData);

  const goBack = useCallback(() => {
    console.log("goBack");
    if (pager.page > 0) {
      pager.prevPage();
    } else {
      ui.openDialog(null);
    }
  }, [pager, ui]);

  const idmClient = useIdmClient();

  const [createAccountCall, createAccountState] = useSafeSistaAsync(
    useCallback(
      async (p: AccountDTO) => {
        const reToken = await runReCaptcha(metadata, "register");
        p.reCaptchaToken = reToken;
        console.log("Creating account");
        const result = await idmClient.createAccount(p);
        setLoggedAccount(result);
      },
      [metadata, setLoggedAccount, idmClient]
    )
  );

  const createAccountFromLastData = useCallback(() => {
    createAccountCall(registerFormData.accountDTO);
  }, [createAccountCall, registerFormData]);

  const formSubmit = useCallback(
    async (dataFromStep: CreateAccountRequestForm) => {
      console.log("Form wide submit at page=" + pager.page);

      // slepime si data s prechazejicim krokem nebo defaultama
      const formData: CreateAccountRequestForm = {
        ...registerFormData,
        ...dataFromStep,
      };
      // a ulozime si je do stavu
      setRegisterFormData(formData);

      if (pager.page == 0) {
        setRegisterFormData(formData);
        pager.nextPage();
        return;
      } else if (pager.page == 1) {
        await createAccountCall(formData.accountDTO);
      } else {
        throw new Error("Unknown page " + pager.page);
      }
    },
    [createAccountCall, pager, registerFormData]
  );

  const titles = [
    t("ucet.registration.basicDialogTitle", "Registrace 1 ze 2"),
    t("ucet.registration.personalDialogTitle", "Registrace 2 ze 2"),
  ];

  return (
    <SistaDialog
      onClose={() => ui.openDialog(null)}
      onBack={goBack}
      title={titles[pager.page]}
    >
      {pager.page == 0 && (
        <RegisterAccountStep1Form
          onSubmit={formSubmit}
          initialValues={registerFormData}
        />
      )}
      {pager.page == 1 && (
        <RegisterAccountStep2Form
          onSubmit={formSubmit}
          initialValues={registerFormData}
        />
      )}
      <Box marginTop={4}>
        <TextLanguageSwitcher />
      </Box>
      <ReCaptchaScript metadata={metadata} />
    </SistaDialog>
  );
};
