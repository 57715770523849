import { Box, CircularProgress } from "@mui/material";
import {
  useDebounce,
  useMaxLengthValidation,
  useMinLengthValidation,
  useRequiredValidation,
} from "@sista/library-stasi";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { PasswordElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { usePasswordValidation } from "../validations/usePasswordValidation";

type SistaPasswordInputProps = {
  name: string;
  label?: string;
  newPassword: boolean;
};

export const SistaPasswordInput = (props: SistaPasswordInputProps) => {
  const { t } = useTranslation("ucet");
  const { watch: formWatch, trigger: formTrigger } = useFormContext();
  const debouncedPassword = useDebounce(formWatch(props.name), 250);

  const [workingCount, setWorkingCount] = useState(0);

  useEffect(() => {
    if (debouncedPassword == null || debouncedPassword.length < 6) return;
    formTrigger(props.name);
  }, [props.name, debouncedPassword, formTrigger]);

  let validation = {
    ...useRequiredValidation(),
    ...useMaxLengthValidation(129),
    ...useMinLengthValidation(10),
  };

  const passwordValidation = usePasswordValidation(setWorkingCount);

  // console.log(workingCount);

  if (props.newPassword) {
    validation = {
      ...validation,
      ...passwordValidation,
    };
  }

  const isLongEnough = debouncedPassword?.length >= 10;

  return (
    <Box sx={{ position: "relative" }}>
      <PasswordElement
        fullWidth={true}
        name={props.name}
        autoComplete={props.newPassword ? "new-password" : "current-password"}
        label={props.label ?? t("ucet.form.password", "Heslo")}
        helperText={
          props.newPassword && !isLongEnough
            ? t(
                "ucet.form.passwordHelp",
                "Heslo musí mít minimálně 10 znaků a obsahovat dostatečnou kombinaci velkých a malých písmen, čísel či speciálních znaků."
              )
            : null
        }
        validation={validation}
      />
      {workingCount > 0 && (
        <CircularProgress
          size={20}
          color={"info"}
          sx={{
            position: "absolute",
            top: "18px",
            right: "64px",
          }}
        ></CircularProgress>
      )}
    </Box>
  );
};
