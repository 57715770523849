import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { AlertsContext } from "@sista/dista-ui";
import { IdmClientContext } from "@sista/idm-client";
import {
  Center,
  LoadingGuard,
  Right,
  SistaDialog,
  UiContext,
  usePager,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

import { useIdmClient } from "../../../api/utils/useIdmClient";
import { IdmUiContext } from "../../../utils/IdmUiContext";
import ReCaptchaScript from "../../ui/ReCaptchaScript";
import { SistaTwoAuthInput } from "../../ui/SistaTwoAuthInput";

export const TwoAuthSettingsDialog = () => {
  const { setLoggedAccount } = useContext(IdmClientContext);
  const { openDialog } = useContext(UiContext);
  const { showSuccess, showError } = useContext(AlertsContext);
  const { t } = useTranslation("ucet");

  const pager = usePager(0);

  const goBack = useCallback(() => {
    console.log("goBack");
    if (pager.page > 0) {
      pager.prevPage();
    } else {
      openDialog(null);
    }
  }, [pager, openDialog]);

  const idmClient = useIdmClient();

  const [twoAuthIsEnabledCall, twoAuthIsEnabledState] = useSafeSistaAsync(
    idmClient.twoAuthIsEnabled
  );
  const [twoAuthEnableCall, twoAuthEnableState] = useSafeSistaAsync(
    idmClient.twoAuthEnable
  );
  const [twoAuthDisableCall, twoAuthDisableState] = useSafeSistaAsync(
    idmClient.twoAuthDisable
  );
  const [twoAuthIsValidCall, twoAuthIsValidState] = useSafeSistaAsync(
    idmClient.twoAuthIsValid
  );
  const [twoAuthRetrieveSecretCodeCall, twoAuthRetrieveSecretCodeState] =
    useSafeSistaAsync(idmClient.twoAuthRetrieveSecretCode);

  const [twoAuthCode, setTwoAuthCode] = useState<string>("");

  const { metadata } = useContext(IdmUiContext);

  useEffect(() => {
    twoAuthIsEnabledCall({});
  }, [twoAuthIsEnabledCall]);

  useEffect(() => {
    if (pager.page == 2) twoAuthRetrieveSecretCodeCall({});
  }, [pager.page, twoAuthRetrieveSecretCodeCall]);

  const finishActivation = useCallback(async () => {
    if (isNaN(Number(twoAuthCode))) return;
    const dto = {
      ...twoAuthRetrieveSecretCodeState.value,
      firstTemporaryCode: Number(twoAuthCode),
    };
    console.log(dto);
    await twoAuthEnableCall(dto);
  }, [twoAuthCode, twoAuthEnableCall, twoAuthRetrieveSecretCodeState.value]);

  useEffect(() => {
    if (twoAuthEnableState.notCalled) return;
    if (twoAuthEnableState.value == true) {
      openDialog(null);
      showSuccess(
        t(
          "ucet.twoAuth.wasEnabled",
          "Dvoufaktorová autorizace byla úspěšně aktivována"
        )
      );
    } else if (twoAuthEnableState.value == false) {
      twoAuthEnableState.reset();
      showError(
        t(
          "ucet.twoAuth.wasNotEnabled",
          "Nepodařilo se aktivovat, proces je nutné zopakovat."
        )
      );
      pager.setPage(1);
    }
  }, [
    twoAuthEnableState.value,
    openDialog,
    showSuccess,
    t,
    twoAuthEnableState.notCalled,
    showError,
    pager,
    twoAuthEnableState.reset,
  ]);

  const finishDeactivate = useCallback(async () => {
    await twoAuthDisableCall({ value: twoAuthCode });
  }, [twoAuthCode, twoAuthDisableCall]);

  useEffect(() => {
    if (twoAuthDisableState.notCalled) return;
    if (twoAuthDisableState.value == true) {
      openDialog(null);
      showSuccess(
        t(
          "ucet.twoAuth.wasDisabled",
          "Dvoufaktorová autorizace byla úspěšně deaktivována"
        )
      );
    } else if (twoAuthDisableState.value == false) {
      twoAuthDisableState.reset();
      showError(
        t(
          "ucet.twoAuth.wasNotDisabled",
          "Nepodařilo se deaktivovat, proces je nutné zopakovat."
        )
      );
      pager.setPage(1);
    }
  }, [
    twoAuthDisableState.value,
    twoAuthDisableState.reset,
    openDialog,
    showSuccess,
    t,
    twoAuthDisableState.notCalled,
    showError,
    pager,
  ]);

  const titles = [
    t("ucet.twoAuth.enable", "Dvoufaktorová autorizace"),
    t("ucet.twoAuth.downloadApp", "Dvoufaktorová autorizace: krok 1 ze 3"),
    t("ucet.twoAuth.configureApp", "Dvoufaktorová autorizace: krok 2 ze 3"),
    t("ucet.twoAuth.verifyApp", "Dvoufaktorová autorizace: krok 3 ze 3"),
  ];

  return (
    <SistaDialog
      onClose={() => openDialog(null)}
      onBack={goBack}
      title={titles[pager.page]}
    >
      <LoadingGuard state={twoAuthIsEnabledState}>
        {pager.page == 0 && (
          <Typography variant={"body1"} marginBottom={2}>
            {t(
              "ucet.twoAuth.info.description",
              "Dvoufaktorová autorizace zvyšuje zabezpečení vašeho účtu. Při přihlášení budete potřebovat kromě hesla také jednorázový kód z mobilní aplikace."
            )}
          </Typography>
        )}

        {twoAuthIsEnabledState.value == true && (
          <>
            {pager.page == 0 && (
              <>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  marginBottom={2}
                >
                  {t(
                    "ucet.twoAuth.info.on",
                    "U vašeho účtu je aktivována dvoufaktorová autorizace. Přejete si ji deaktivovat?"
                  )}
                </Typography>

                <Right>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={pager.nextPage}
                  >
                    {t(
                      "ucet.twoAuth.disable",
                      "Deaktivovat dvoufaktorovou autorizaci"
                    )}
                  </Button>
                </Right>
              </>
            )}
            {pager.page == 1 && (
              <>
                <Typography variant={"body1"} sx={{ marginBottom: 2 }}>
                  {t(
                    "ucet.twoAuth.info.deactivate",
                    "Pro deaktivaci je potřeba zadat kód, který se zobrazuje v mobilní aplikaci Google Authenticator."
                  )}
                </Typography>

                <Center sx={{ marginBottom: 4 }}>
                  <SistaTwoAuthInput
                    onCodeProvided={(token) => setTwoAuthCode(token)}
                  />
                </Center>

                <Right>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => finishDeactivate()}
                  >
                    {t(
                      "ucet.login.twoAuthDialog.deactivateButton",
                      "Vypnout dvoufaktorovou autorizaci"
                    )}
                  </Button>
                </Right>
              </>
            )}
          </>
        )}
        {twoAuthIsEnabledState.value == false && (
          <>
            {pager.page == 0 && (
              <>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  marginBottom={2}
                >
                  {t(
                    "ucet.twoAuth.info.off",
                    "U vašeho účtu není aktivována dvoufaktorová autorizace. Přejete si ji aktivovat?"
                  )}
                </Typography>
                <Right>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={pager.nextPage}
                  >
                    {t(
                      "ucet.twoAuth.enableWizard",
                      "Spustit průvodce aktivací"
                    )}
                  </Button>
                </Right>
              </>
            )}
            {pager.page == 1 && (
              <>
                <Typography variant={"body1"} marginBottom={2}>
                  {t(
                    "ucet.twoAuth.info.activateStep1",
                    "Nejprve si prosím nainstalujte aplikaci Google Authenticator na svůj mobilní telefon."
                  )}
                </Typography>
                <Stack direction={"row"} marginBottom={2}>
                  <Link
                    href={
                      "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                    }
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    sx={{ flexGrow: 1, flexShrink: 1, textAlign: "center" }}
                  >
                    <img
                      src={"/ucet/images/google_play.png"}
                      alt={"Google Play"}
                      height={"50px"}
                    />
                  </Link>
                  <Box sx={{ width: "16px" }} />
                  <Link
                    href={
                      "https://apps.apple.com/app/google-authenticator/id388497605"
                    }
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    sx={{ flexGrow: 1, flexShrink: 1, textAlign: "center" }}
                  >
                    <img
                      src={"/ucet/images/apple_appstore.png"}
                      alt={"App Store"}
                      height={"50px"}
                    />
                  </Link>
                </Stack>
                <Right>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={pager.nextPage}
                  >
                    {t("ucet.twoAuth.stepTwo", "Pokračovat")}
                  </Button>
                </Right>
              </>
            )}
            {pager.page == 2 && (
              <>
                <Typography variant={"body1"} marginBottom={2}>
                  {t(
                    "ucet.twoAuth.info.activateStep2",
                    "Spusťte aplikaci, zvolte možnost Přidat kód a naskenujte následují QR kód."
                  )}
                </Typography>
                <LoadingGuard state={twoAuthRetrieveSecretCodeState}>
                  <Center>
                    <div
                      style={{
                        background: "white",
                        padding: "16px",
                        width: "200px",
                        margin: "0 auto",
                      }}
                    >
                      {twoAuthRetrieveSecretCodeState.value?.qrImageUrl !=
                        null && (
                        <QRCode
                          size={256}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={
                            twoAuthRetrieveSecretCodeState.value?.qrImageUrl
                          }
                          viewBox={`0 0 256 256`}
                        />
                      )}
                    </div>
                  </Center>
                </LoadingGuard>
                <Right>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={pager.nextPage}
                  >
                    {t("ucet.twoAuth.stepThree", "Pokračovat")}
                  </Button>
                </Right>
              </>
            )}
            {pager.page == 3 && (
              <>
                <Typography variant={"body1"} marginBottom={2}>
                  {t(
                    "ucet.twoAuth.info.activateStep3",
                    "Nyní se v aplikaci zobrazuje jednorázový šesticiferný kód. Zadejte ho do následujícího textového pole, abychom ověřili, že je aplikace správně nastavená. Tím také dojde k aktivaci dvoufaktorové autorizace."
                  )}
                </Typography>
                <Center sx={{ marginBottom: 2 }}>
                  <SistaTwoAuthInput
                    onCodeProvided={(token) => setTwoAuthCode(token)}
                  />
                </Center>
                <Right>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => finishActivation()}
                  >
                    {t("ucet.twoAuth.stepFinish", "Dokončit aktivaci")}
                  </Button>
                </Right>
              </>
            )}
          </>
        )}
      </LoadingGuard>
      <ReCaptchaScript metadata={metadata} />
    </SistaDialog>
  );
};
