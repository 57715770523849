import { IdmClientContext } from "@sista/idm-client";
import {
  SmartButton,
  useSafeSistaAsync,
  useScript,
} from "@sista/library-stasi";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useIdmClient } from "../../api/utils/useIdmClient";
import { IdmUiContext, Metadata } from "../../utils/IdmUiContext";

type GoogleButtonProps = {
  children?: React.ReactNode;
};

function startSignIn(metadata: Metadata, callback: (response: string) => void) {
  const clientId = metadata["REACT_APP_GOOGLE_CLIENT_ID"];

  let redirectUri = new URL(
    process.env.REACT_APP_UCET_ROOT,
    window.location.origin
  ).toString();
  if (!redirectUri.endsWith("/")) {
    redirectUri += "/";
  }
  console.log(
    "Starting GOOGLOGIN with redirect_uri=" +
      redirectUri +
      " and client_id=" +
      clientId +
      " (those values must match server side, search for GOOGLOGIN in server log)"
  );
  const c = google.accounts.oauth2.initCodeClient({
    client_id: clientId,
    scope: "openid email profile",
    redirect_uri: redirectUri,
    callback: function (codeResponse: any) {
      callback(codeResponse.code);
    },
  });
  c.requestCode();
}

export const GoogleScript = () => {
  const url = "https://accounts.google.com/gsi/client";
  const script = useScript(url);
  return <></>;
};

export const GoogleButton = (props: GoogleButtonProps) => {
  const { t } = useTranslation("ucet");
  const metadata = useContext(IdmUiContext)?.metadata;

  const { loginViaToken } = useIdmClient();

  const [loginCall, loginState] = useSafeSistaAsync(
    useCallback(
      async (token: string) => await loginViaToken({ value: token }),
      [loginViaToken]
    )
  );

  const { setLoggedAccount } = useContext(IdmClientContext);

  const receiveGoogleToken = useCallback<(response: string) => void>(
    (response: string) => {
      console.log("Incomming Google token");
      loginCall(response);
    },
    [loginCall]
  );

  useEffect(() => {
    if (loginState.value) {
      console.log("User is logged through Google");
      setLoggedAccount(loginState.value);
    }
  }, [setLoggedAccount, loginState.value]);

  const startProcessHandler = () => startSignIn(metadata, receiveGoogleToken);

  return (
    <SmartButton
      onClick={startProcessHandler}
      state={loginState}
      variant={props.children ? "text" : "outlined"}
    >
      {props.children
        ? props.children
        : t("ucet.button.google", "Použít účet Google")}
    </SmartButton>
  );
};

export default GoogleButton;
