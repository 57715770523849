import { isTrue } from "@sista/library-stasi";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useIdmClient } from "../../api/utils/useIdmClient";

export const usePasswordValidation = (
  workingSetter: Dispatch<SetStateAction<number>>
) => {
  const { t } = useTranslation("ucet");
  const idmClient = useIdmClient();

  const isPasswordStrong = useCallback(
    async (password?: string): Promise<boolean> => {
      console.log("Checking isPasswordStrong");
      if (password == null) return false;
      if (password.length < 6) return false;
      const res = await idmClient.calculatePasswordStrength({
        value: password,
      });
      return res === "STRONG" || res === "EXTRA_STRONG";
    },
    [idmClient]
  );

  const isPasswordDictionary = useCallback(
    async (password?: string): Promise<boolean> => {
      console.log("Checking isPasswordDictionary");
      if (password == null) return false;
      if (password.length < 6) return false;
      return isTrue(await idmClient.isPassSimilar({ value: password }));
    },
    [idmClient]
  );

  const validateAll = useCallback(
    async (value: string): Promise<string> => {
      console.log("Validating password");
      try {
        workingSetter((prev) => prev + 1);
        try {
          if (value == null || value.length < 10)
            return t(
              "common.error.weakPassword",
              "Toto heslo není dostatečně silné, použijte více znaků a kombinaci velkých a malých písmen, čísel či speciálních znaků."
            );
          const strongResult = await isPasswordStrong(value);
          if (!strongResult)
            return t(
              "common.error.weakPassword",
              "Toto heslo není dostatečně silné, použijte více znaků a kombinaci velkých a malých písmen, čísel či speciálních znaků."
            );
          const dictionaryResult = await isPasswordDictionary(value);
          if (dictionaryResult == true)
            return t(
              "common.error.dictionaryPassword",
              "Toto heslo se příliš podobá běžně používaným heslům a lze ho uhádnout."
            );
        } catch (e) {
          console.error("Cannot validate password", e);
          return t(
            "common.error.passwordValidationBroken",
            "Nepodařilo se zkontrolovat kvalitu hesla, zkontrolujte prosím vaše internetové připojení."
          );
        }
      } finally {
        workingSetter((prev) => prev - 1);
      }
      return undefined;
    },
    [workingSetter, t, isPasswordStrong, isPasswordDictionary]
  );

  return {
    validate: validateAll,
  };
};
